import { FaWhatsapp } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { Container, Row, Col, Card } from "react-bootstrap";

import { Slider, Slide } from "./Slider";
import { WhatsApp } from "./contact/WhatsApp";
import { MailTo } from "./contact/MailTo";

let sale_phones = ["56962083481"];

let principal_contact_email = "contacto@guten.cl";

interface Props {
  title: string;
  description: string;
  slides?: Slide[];
}

const ProductDetail = ({ title, description, slides }: Props) => {
  return (
    <Container fluid>
      <Row>
        <Col>
          <Card className="shadow-lg border-0 h-100 w-100 mb-5">
            <h1 className="p-3 text-center">{title}</h1>
            <Card.Body>
              <Row xs={1} sm={1} md={2} lg={2}>
                <Col>{slides && <Slider Slides={slides} />}</Col>
                <Col>
                  <article>
                    <section>
                      <h5>{description}</h5>
                    </section>
                    <footer>
                      <Row>
                        <h2>Pregunta por el producto</h2>
                        <Col>
                          {sale_phones.map((phone) => (
                            <WhatsApp
                              key={phone}
                              phone={phone}
                              message={`Hola, Estoy interesado en el producto *${title}* de Guten Prints`}
                              isButton={true}
                            >
                              <FaWhatsapp /> Escribenos
                            </WhatsApp>
                          ))}
                        </Col>
                        <Col>
                          <MailTo
                            email={principal_contact_email}
                            subject={`Contacto web - ${title} - Guten Prints Cl`}
                            message={`Hola, Estoy interesado en el producto ${title} de Guten Prints`}
                            isButton={true}
                          >
                            <HiOutlineMail /> Envía un email
                          </MailTo>
                        </Col>
                      </Row>
                    </footer>
                  </article>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export { ProductDetail };
