import { Link } from "react-router-dom";

interface Props {
  imageSource: string;
  imageAltName: string;
  slideURL?: string;
}

const SliderImage = ({ imageSource, imageAltName, slideURL }: Props) => (
  <Link to={slideURL ? encodeURI(slideURL) : "#"}>
    <img
      className="d-block slider"
      src={imageSource}
      alt={imageAltName}
      width={0}
      height={0}
      sizes="100vw"
    />
  </Link>
);

export { SliderImage };
