import { Carousel } from "react-bootstrap";

import { SliderImage } from "./SliderImage";

export interface Slide {
  ImageSource: string;
  ImageAltName: string;
  SlideTitle?: string;
  SlideDescription?: string;
  SlideURL?: string;
}

interface Props {
  Slides: Slide[];
}

const Slider = ({ Slides }: Props) => {
  return (
    <Carousel fade>
      {Slides.map((slide) => (
        <Carousel.Item key={slide.ImageAltName}>
          <SliderImage
            imageSource={slide.ImageSource}
            imageAltName={slide.ImageAltName}
            slideURL={slide.SlideURL && encodeURI(slide.SlideURL)}
          />
          {("SlideTitle" in slide || "SlideDescription" in slide) && (
            <Carousel.Caption className="shadow-lg p-3 mb-5 bg-secondary bg-opacity-25 rounded">
              {slide.SlideTitle !== undefined && (
                <h3 className="text-dark">{slide.SlideTitle}</h3>
              )}
              {slide.SlideDescription !== undefined && (
                <p className="text-dark">{slide.SlideDescription}</p>
              )}
            </Carousel.Caption>
          )}
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export { Slider };
