import { ProductCatalog } from "../../components/ProductCatalog";
import { SEO } from "../../components/SEO";

import { products } from "../../data/products";

const Productos = () => (
  <>
    <SEO title="Productos de imprenta y publicidad" />
    <ProductCatalog products={products} />
  </>
);

export { Productos };
