import { Fragment } from "react";

import { SEO } from "../../components/SEO";
import { Contact } from "../../components/Contact";

const Contacto = () => (
  <Fragment>
    <SEO title="Contacta y contrata productos de publicidad" />
    <Contact />
  </Fragment>
);

export { Contacto };
