import { Fragment } from "react";
import { FrameContact } from "../../components/FrameContact";
import { Container, Row, Col } from "react-bootstrap";

import { Slider } from "../../components/Slider";
import { SEO } from "../../components/SEO";
import { slides } from "../../data/slider";

const Index = () => {
  return (
    <Fragment>
      <SEO
        title="Home publicidad e imprenta"
        description="impresiones, instalaciones, montajes, entre otros servicios publicitarios"
        keywords="imprenta, publicidad, grafica, montajes, marketing"
      />
      <Slider Slides={slides} />
      <Container className="my-4 shadow">
        <Row>
          <Col>
            <section className="clearfix">
              <h1 className="text-3xl font-bold underline">¿Qué es guten?</h1>
              <img
                src="./images/logo.jpg"
                alt="Logo Guten"
                className="col-md-6 float-md-end mb-3 ms-md-3"
                height={300}
              />
              <p>
                Guten es una empresa dedicada a la impresión gráfica,
                especializada en el desarrollo e implementación de soluciones
                visuales, que incluyen impresiones, instalaciones, montajes,
                entre otros servicios. Nacimos con el propósito de ofrecer apoyo
                a personas y empresas con pedidos de diferentes tamaños, ya sean
                grandes, medianos o pequeños, en el ámbito del desarrollo e
                implementación gráfica
              </p>
            </section>
          </Col>
        </Row>
      </Container>
      <FrameContact />
    </Fragment>
  );
};

export { Index };
