import { Helmet, HelmetProvider } from "react-helmet-async";

interface SEOArgs {
  title: string;
  description?: string;
  keywords?: string;
}

const SEO = (seoArgs: SEOArgs) => (
  <HelmetProvider>
    <Helmet>
      <title>{seoArgs.title} - Guten Prints</title>
      {seoArgs.description && (
        <meta
          name="description"
          content={`${seoArgs.description} - Guten Prints - Santiago - Chile`}
        />
      )}
      {seoArgs.keywords && (
        <meta
          name="keywords"
          content={`${seoArgs.keywords}, Santiago, Chile`}
        ></meta>
      )}
    </Helmet>
  </HelmetProvider>
);

export { SEO };
