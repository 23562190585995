import { Link } from "react-router-dom";

interface Props {
  email: string;
  subject: string;
  message: string;
  isButton: boolean;
  children?: any;
}

const MailTo = ({ email, subject, message, isButton, children }: Props) => (
  <Link
    to={`mailto:${email}?Subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(message)}`}
    rel="noopener noreferrer"
    target="_blank"
    {...(isButton && { role: "button" })}
    {...(isButton && { className: "btn btn-success btn-lg" })}
  >
    {children}
  </Link>
);

export { MailTo };
