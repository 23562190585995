import { Link } from "react-router-dom";

interface Props {
  phone: string;
  message: string;
  isButton: boolean;
  children?: any;
}

const WhatsApp = ({ phone, message, isButton, children }: Props) => (
  <Link
    to={`https://wa.me/${phone}?text=${encodeURIComponent(message)}`}
    rel="noopener noreferrer"
    target="_blank"
    {...(isButton && { role: "button" })}
    {...(isButton && { className: "btn btn-success btn-lg" })}
  >
    {children}
  </Link>
);

export { WhatsApp };
