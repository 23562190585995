import { NavbarMenu } from "./NavbarMenu"

const Header = () =>
(
    <header >
        <NavbarMenu />
    </header>
)

export { Header };
