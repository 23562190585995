import logo from "./../images/logo.svg";
import { Link, useLocation } from "react-router-dom";
import { Navbar, Container, Nav, NavItem } from "react-bootstrap";

interface Item {
  PageName: string;
  URL: string;
}

let Items: Item[] = [
  {
    PageName: "Home",
    URL: "/",
  },
  // {
  //     PageName: "Nosotros",
  //     URL: "/nosotros"
  // },
  {
    PageName: "Productos",
    URL: "/productos",
  },
  {
    PageName: "Contacto",
    URL: "/contacto",
  },
];

const NavbarMenu = () => {
  const location = useLocation();
  return (
    <Navbar
      bg="white"
      data-bs-theme="white"
      className="py-0 navbar--fixed-top"
      collapseOnSelect
      expand="lg"
    >
      <Container>
        <Navbar.Brand>
          <Link to="/" className="navbar-brand">
            <img src={logo} alt="Logo Guten" height={40} width={120} />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-end"
        >
          <Nav variant="pills" defaultActiveKey="/">
            {Items.map((value) => (
              <NavItem
                key={value.PageName}
                className={
                  "pt-3 " +
                  (location.pathname.split("/")[1] === value.URL.split("/")[1]
                    ? "header-nav-item"
                    : null)
                }
              >
                <Link
                  className={
                    "nav-link " +
                    (location.pathname.split("/")[1] === value.URL.split("/")[1]
                      ? "header-nav-link"
                      : null)
                  }
                  key={value.PageName}
                  to={value.URL}
                >
                  {value.PageName}
                </Link>
              </NavItem>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export { NavbarMenu };
