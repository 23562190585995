import React from "react";
import { Route, Routes } from "react-router-dom";

// Layouts
import { PublicLayout } from "./layouts/PublicLayout";

// Pages
import { Index } from "./pages/home/Index";
import { Productos } from "./pages/productos";
import { Contacto } from "./pages/contacto";
import { DetalleProducto } from "./pages/productos/detalle";

// Styles
import "./styles/globals.css";

// import logo from './logo.svg';

function App() {
  return (
    <Routes>
      <Route path="/" element={<PublicLayout />}>
        <Route index element={<Index />} />
        <Route path="productos">
          <Route index element={<Productos />} />
          <Route path=":product" element={<DetalleProducto />} />
        </Route>
        <Route path="contacto" element={<Contacto />} />
      </Route>
    </Routes>
  );
}

export default App;
