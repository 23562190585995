import { useParams } from "react-router-dom";
import { ProductDetail } from "../../components/ProductDetail";
import { SEO } from "../../components/SEO";
import { ProductData } from "../../components/ProductCatalog";

import { products } from "../../data/products";

const DetalleProducto = () => {
  let params = useParams();
  let productURL: string = params.product as string;
  let product: ProductData = products.filter(({ to }) =>
    to.includes(productURL)
  )[0];
  return (
    <>
      <SEO title={product.title} keywords={product.title} />
      <ProductDetail
        title={product.title}
        description={product.description}
        slides={product.slides}
      />
    </>
  );
};

export { DetalleProducto };
