import { FaWhatsapp } from "react-icons/fa";
import { HiOutlineMail, HiPhone } from "react-icons/hi";
import { BsInstagram } from "react-icons/bs";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import { WhatsApp } from "./contact/WhatsApp";
import { MailTo } from "./contact/MailTo";

let sale_phones = ["56962083481"];

let contact_emails = ["contacto@guten.cl"];

interface SocialMedia {
  Icon: any;
  UserName: string;
  URL: string;
}

let social_media_accounts: SocialMedia[] = [
  {
    Icon: <BsInstagram />,
    UserName: "@gutenchile",
    URL: `https://instagram.com/gutenchile`,
  },
];

const FrameContact = () => (
  <Container className="p-5 my-3 shadow-lg">
    <Row>
      <h1>Contáctanos</h1>
      <Col className="text-center">
        <h2>Información</h2>
        {contact_emails.map((email) => (
          <MailTo
            key={email}
            email={email}
            subject="Contacto web - Guten Prints Cl"
            message="Hola, Estoy interesado en los productos y servicios de Guten Prints"
            isButton={false}
          >
            <HiOutlineMail /> {email}
          </MailTo>
        ))}
      </Col>
      <Col className="text-center">
        <h2>Ventas</h2>
        {sale_phones.map((phone) => (
          <Link key={phone} to={`tel:+${phone}`}>
            <HiPhone /> {phone}
          </Link>
        ))}
      </Col>
      <Col className="text-center">
        <h2>Redes sociales</h2>
        {social_media_accounts.map((value) => (
          <Link
            key={value.UserName}
            to={value.URL}
            rel="noopener noreferrer"
            target="_blank"
          >
            {value.Icon} {value.UserName}
          </Link>
        ))}
      </Col>
      <Col className="text-center">
        <h2>Whatsapp</h2>
        {sale_phones.map((phone) => (
          <WhatsApp
            key={phone}
            phone={phone}
            message="Hola, Estoy interesado en los productos y servicios de Guten Prints"
            isButton={false}
          >
            <FaWhatsapp /> {phone}
          </WhatsApp>
        ))}
      </Col>
    </Row>
  </Container>
);

export { FrameContact };
