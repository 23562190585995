import { Container, Row, Col } from "react-bootstrap";

import { Product } from "./Product";
import { Slide } from "./Slider";

export interface ProductData {
  title: string;
  description: string;
  imageSource: string;
  to: string;
  slides?: Slide[];
}

interface Props {
  products: ProductData[];
}

const ProductCatalog = ({ products }: Props) => (
  <Container className="py-5">
    <h1>Productos</h1>
    <Row xs={1} md={2} lg={4} className="g-4">
      {products.map((product) => (
        <Col key={product.title}>
          <Product
            Title={product.title}
            Description={product.description}
            ImageSource={product.imageSource}
            To={product.to}
          ></Product>
        </Col>
      ))}
    </Row>
  </Container>
);

export { ProductCatalog };
