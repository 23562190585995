import footer from "./../images/footer.png"
import { Container, Navbar, Row } from 'react-bootstrap';

const currentYear = new Date().getFullYear();
const NavbarFooter = () =>
(
    <Navbar bg="dark" data-bs-theme="dark">
        <Container className="justify-content-center">
            <Row>
                <Navbar.Brand className="text-light text-center">
                    <img
                        src={footer}
                        alt="Logo Guten Footer"
                        width={400}
                        height={150}
                    />
                    <br />
                    <p className="text-wrap">
                        Derechos de autor ©{currentYear} Guten Print works SpA. Todos los derechos reservados
                    </p>
                </Navbar.Brand>

            </Row>
        </Container>
    </Navbar>
)

export { NavbarFooter };
