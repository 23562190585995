import { Header } from "../components/Header";
import { NavbarFooter } from "../components/NavbarFooter";
import { Outlet } from 'react-router-dom';

const PublicLayout = ({ children }: any) =>
(
    <main>
        <Header />
        <Outlet />
        <NavbarFooter />
    </main>
)

export { PublicLayout };
