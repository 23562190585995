import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

interface Props {
  ImageSource: string;
  Description: string;
  Title: string;
  To: string;
}

const Product = ({ Title, Description, ImageSource, To }: Props) => {
  return (
    <Link
      to={To}
      className="link-dark link-offset-2 link-underline-opacity-0 link-underline-opacity-0-hover"
    >
      <Card className="shadow-lg border-0 h-100">
        <Card.Img
          className="rounded mx-auto d-block"
          variant="top"
          src={ImageSource}
          alt={Title}
          height={200}
        />
        <Card.Body>
          <Card.Title className="text-center">{Title}</Card.Title>
          <Card.Text className="text-truncate">{Description}</Card.Text>
        </Card.Body>
      </Card>
    </Link>
  );
};

export { Product };
