import { ChangeEvent } from "react";
import {
  Col,
  Row,
  Card,
  Form,
  FloatingLabel,
  Container,
} from "react-bootstrap";
import { MailTo } from "../contact/MailTo";
import { useState } from "react";

interface Props {
  email: string;
  subject: string;
}

const MailToForm = ({ email, subject }: Props) => {
  const [message, setMessage] = useState("");

  const handleChangeMessage = (e: ChangeEvent<HTMLInputElement>) =>
    setMessage(e.target.value);

  return (
    <Container className="container-green h-100">
      <Row className="align-items-center h-100">
        <Col
          xs={{ span: 10, offset: 1 }}
          sm={{ span: 10, offset: 1 }}
          md={{ span: 8, offset: 2 }}
          lg={{ span: 8, offset: 2 }}
          className="mx-auto"
        >
          <Card>
            <Card.Body>
              <Card.Title>Envianos un email</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                Solicita información de nosotros
              </Card.Subtitle>
              <Form>
                <FloatingLabel controlId="messageTextarea" label="Mensaje">
                  <Form.Control
                    as="textarea"
                    placeholder="Por favor agrega un comentario"
                    className="h-25"
                    value={message}
                    onChange={handleChangeMessage}
                  />
                </FloatingLabel>
                <span className="d-grid gap-2">
                  <MailTo
                    email={email}
                    subject={subject}
                    message={message}
                    isButton={true}
                  >
                    Enviar
                  </MailTo>
                </span>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export { MailToForm };
