import { Slide } from "../components/Slider";
import PVCPortada from "../images/productos/Impresión directa en tela PVC/Portada.jpg";
import FlejerasPortada from "../images/productos/Flejeras papel sintetico/Portada.jpg";

const slides: Slide[] = [
  {
    ImageSource: PVCPortada,
    ImageAltName: "Impresión directa en Tela PVC",
    SlideTitle: "Impresión directa en Tela PVC",
    SlideURL: "/productos/impresion-directa-en-tela-pcv",
  },
  {
    ImageSource: FlejerasPortada,
    ImageAltName: "Flejeras papel sintetico",
    SlideTitle: "Flejeras papel sintetico",
    SlideURL: "/productos/flejeras-papel-sintetico",
  },
];

export { slides };
