import { ProductData } from "../components/ProductCatalog";
import PVCPortada from "../images/productos/Impresión directa en tela PVC/Portada.jpg";
import PVCSlide1 from "../images/productos/Impresión directa en tela PVC/Slide1.jpg";
import PVCSlide2 from "../images/productos/Impresión directa en tela PVC/Slide2.jpg";
import FlejerasSinteticasPortada from "../images/productos/Flejeras papel sintetico/Portada.jpg";
import FlejerasSinteticasSlide1 from "../images/productos/Flejeras papel sintetico/Slide1.jpg";
import FlejerasSinteticasSlide2 from "../images/productos/Flejeras papel sintetico/Slide2.jpg";
import CouchePortada from "../images/productos/Papel couche/Portada.jpg";
import FlejerasCartulinaPortada from "../images/productos/Flejera cartulina/Portada.jpg";
import FlejerasCartulinaSlide1 from "../images/productos/Flejera cartulina/Slide1.jpg";
import CajasCartulinaPortada from "../images/productos/Cajas cartulina/Portada.jpg";
import CajasCartulinaSlide1 from "../images/productos/Cajas cartulina/Slide1.jpg";

const products: ProductData[] = [
  {
    title: "Impresión directa en tela pcv",
    description:
      "El PVC es un material flexible y resistente a la intemperie el cual permite tener una superficie lisa y homogénea.\n\nLas caracteristicas indicadas nos permiten que las impresiones tengan una nitidez y color HD.",
    imageSource: PVCPortada,
    to: "/productos/impresion-directa-en-tela-pcv",
    slides: [
      {
        ImageSource: PVCPortada,
        ImageAltName: "Impresión directa en tela pcv - Portada",
      },
      {
        ImageSource: PVCSlide1,
        ImageAltName: "Impresión directa en tela pcv - Slide 1",
      },
      {
        ImageSource: PVCSlide2,
        ImageAltName: "Impresión directa en tela pcv - Slide 2",
      },
    ],
  },
  {
    title: "Flejeras papel sintetico",
    description:
      "El papel sintetico es un material de artificial que cuenta con una película plástica opaca o transparente en la que se puede escribir y hacer impresiones sobre ella.\n\nLa flejera es una impresión gráfica pequeña en forma de tira.",
    imageSource: FlejerasSinteticasPortada,
    to: "/productos/flejeras-papel-sintetico",
    slides: [
      {
        ImageSource: FlejerasSinteticasPortada,
        ImageAltName: "Flejeras papel sintetico - Portada",
      },
      {
        ImageSource: FlejerasSinteticasSlide1,
        ImageAltName: "Flejeras papel sintetico - Slide 1",
      },
      {
        ImageSource: FlejerasSinteticasSlide2,
        ImageAltName: "Flejeras papel sintetico - Slide 2",
      },
    ],
  },
  {
    title: "Papel couche",
    description:
      "El papel couche se caracteriza por no tener poros y tener un recubrimiento mate o brillante.",
    imageSource: CouchePortada,
    to: "/productos/papel-couche",
    slides: [
      {
        ImageSource: CouchePortada,
        ImageAltName: "Papel couche - Calendario - Portada",
      },
    ],
  },
  {
    title: "Flejera cartulina",
    description: "",
    imageSource: FlejerasCartulinaPortada,
    to: "/productos/flejera-cartulina",
    slides: [
      {
        ImageSource: FlejerasCartulinaPortada,
        ImageAltName: "Flejera cartulina - Portada",
      },
      {
        ImageSource: FlejerasCartulinaSlide1,
        ImageAltName: "Flejera cartulina - Slide 1",
      },
    ],
  },
  {
    title: "Cajas cartulina",
    description: "",
    imageSource: CajasCartulinaPortada,
    to: "/productos/cajas-cartulina",
    slides: [
      {
        ImageSource: CajasCartulinaPortada,
        ImageAltName: "Cajas cartulina - Portada",
      },
      {
        ImageSource: CajasCartulinaSlide1,
        ImageAltName: "Cajas cartulina - Slide 1",
      },
    ],
  },
];

export { products };
